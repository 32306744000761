import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router";
import CardCheckout from "./CardCheckout";
import api from "./api";
import Loader from "./Loader";
import { checkImageUrl } from "./utils";

const Home = () => {
  const { uniqueKey } = useParams();
  const [stripePromise, setStripePromise] = useState();
  const [paymentDetails, setPaymentDetails] = useState({});

  const intializeStripe = async () => {
    try {
      const { data } = await api.post("/stripe/create-payment-intent", { unique_link_key: uniqueKey });
      const validImage = await checkImageUrl(data.data.logo);
      if (!validImage) delete data.data.logo;
      if (data.data.payment_status != "NONE" )
        return (window.location.href = `${process.env.REACT_APP_API_URL}/payment-checkout/${uniqueKey}`);
      setStripePromise(loadStripe(data.data.stripe_key));
      setPaymentDetails({ ...data.data, unique_link_key: uniqueKey });
    } catch (err) {
      window.location.href = `${process.env.REACT_APP_API_URL}/stripe/failure?unique_link_key=${uniqueKey}`;
    }
  };

  useEffect(() => {
    intializeStripe();
  }, []);

  if (!stripePromise) return <Loader />;

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Montserrat&display=swap",
          },
        ],
      }}
    >
      <CardCheckout paymentDetails={paymentDetails} />
    </Elements>
  );
};

export default Home;
