import amex from "../assets/images/credit_card_brands/AmericanExpress.svg";
import cirrus from "../assets/images/credit_card_brands/Cirrus.svg";
import diners from "../assets/images/credit_card_brands/DinersClub.svg";
import discover from "../assets/images/credit_card_brands/Discover.svg";
import placeholder from "../assets/images/credit_card_brands/Placeholder.svg";
import jcb from "../assets/images/credit_card_brands/JCB.svg";
import maestro from "../assets/images/credit_card_brands/Maestro.svg";
import mastercard from "../assets/images/credit_card_brands/MasterCard.svg";
import unionPay from "../assets/images/credit_card_brands/UnionPay.svg";
import visa from "../assets/images/credit_card_brands/Visa.svg";

export const checkImageUrl = async (imgUrl) => {
  const verifyImage = (blob) => {
    return new Promise((res, rej) => {
      let url = URL.createObjectURL(blob);
      const img = new Image();
      img.src = url;
      if (img.complete) return res(true);
      img.onload = () => res(true);
      img.onerror = () => res(false);
    });
  };

  try {
    let res = await fetch(imgUrl);
    if (res.status != 200) return false;
    let blob = await res.blob();
    return verifyImage(blob);
  } catch (err) {
    return false;
  }
};

export const formatMoney = (amount, currency) => {
  return new Intl.NumberFormat(
    "en-UK",
    currency
      ? {
          style: "currency",
          currency,
        }
      : {}
  )
    .format(Math.round((amount || 0) * 100) / 100)
    .replace(/^(\D+)/, "$1 ");
};

export const cardBrands = { amex, cirrus, diners, discover, placeholder, jcb, maestro, mastercard, unionPay, visa };
