import React from "react";

const Loader = () => {
  return (
    <div className="fallback-spinner">
      <div className="loading component-loader">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "calc(50%-100px)",
          top: "50%",
          width: "100%",
          textAlign: "center",
        }}
      >
        <div className="font-medium-3 mt-2">We're fetching details of your payment, please wait.</div>
      </div>
    </div>
  );
};

export default Loader;
