import { cardBrands, formatMoney } from "./utils";

import { FormGroup, Row, Col, Button, Label, Spinner } from "reactstrap";
import { Lock, ArrowLeft } from "react-feather";
import { useState } from "react";
import { Formik, Form, Field } from "formik";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as Yup from "yup";
import api from "./api";

const formSchema = Yup.object().shape({
  email: Yup.string().email().required("required"),
  nameOnCard: Yup.string().required("required"),
  card_number: Yup.boolean().required("required"),
  expiry: Yup.boolean().required("required"),
  cvv: Yup.boolean().required("required"),
});

const baseInputStyles = {
  fontFamily: "'Montserrat', Helvetica, Arial, serif",
  color: "#6e6b7b",
};

const CardCheckout = ({ paymentDetails }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [cardBrand, setCardBrand] = useState("placeholder");
  const [cardDeclined, setCardDeclined] = useState(false);

  const handleFormSubmit = async (data) => {
    try {
      if (formSubmitting || cardDeclined) return;
      if (!stripe || !elements) return;
      setFormSubmitting(true);
      const cardData = elements.getElement(CardNumberElement);

      const { error, paymentIntent } = await stripe.confirmCardPayment(paymentDetails.stripe_payment_secret, {
        payment_method: {
          card: cardData,

          billing_details: { name: data.nameOnCard, email: data.email },
        },
      });

      if (error ||  error?.code == "card_declined") {
        setFormSubmitting(false);
        return setCardDeclined(true);
      }

      const status = error ? "failure" : "success";
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
      const stripeRes = error
        ? { ...error.payment_intent, unique_link_key: paymentDetails.unique_link_key }
        : { ...paymentIntent, unique_link_key: paymentDetails.unique_link_key };
      
      await api.post("/stripe/notification", stripeRes);
       window.location.href = `${process.env.REACT_APP_API_URL}/stripe/${status}?unique_link_key=${paymentDetails.unique_link_key}`;
    } catch (err) {
      console.log(err);
       window.location.href = `${process.env.REACT_APP_API_URL}/stripe/failure?unique_link_key=${paymentDetails.unique_link_key}`;
    }
  };

  const goBack = () => {
    if (paymentDetails.can_go_back)
      window.location.href = `${process.env.REACT_APP_API_URL}/stripe/back?unique_link_key=${paymentDetails.unique_link_key}`;
  };

  const handleBrandChange = (brandName) => {
    if (cardBrands[brandName]) setCardBrand(brandName);
    else setCardBrand("placeholder");
  };

  return (
    <div className="h-100">
      <div
        className="cursor-pointer text-align-left d-flex w-100 d-lg-none"
        style={{
          position: "relative",
          padding: "20px",
          position: "fixed",
          zIndex: 2,
          borderBottom: "1px solid #d8d6de",
          background: "#fff",
        }}
        onClick={goBack}
      >
        <ArrowLeft className="margin-auto" style={{ marginRight: "20px" }} />
        {paymentDetails.logo ? (
          <img className="img-fluid responsive-brand-logo" src={paymentDetails.logo} />
        ) : (
          <h4 style={{ margin: "auto 0" }}>Back</h4>
        )}
      </div>
      <Row className="h-100 m-0">
        <Col lg="6" className="d-none d-lg-block p-0">
          <div className="d-flex align-items-center h-100 justify-content-end">
            <div
              style={{ width: "540px", height: "413px" }}
              className="d-flex flex-column justify-content-between responsive-padding"
            >
              <div>
                <div
                  className="cursor-pointer text-align-left"
                  style={{ width: "fit-content", position: "relative" }}
                  onClick={goBack}
                >
                  <ArrowLeft
                    className="margin-auto"
                    style={{ position: "absolute", left: "-50px", top: "50%", transform: "translate(0, -50%)" }}
                  />
                  {paymentDetails.logo ? (
                    <img className="img-fluid responsive-brand-logo" src={paymentDetails.logo} />
                  ) : (
                    <h4 style={{ margin: "auto 0" }}>Back</h4>
                  )}
                </div>
                <div className="my-4">
                  <div className="font-weight-bolder">Pay</div>
                  <div className="font-large-1 font-weight-bolder">
                    {formatMoney(paymentDetails.amount, paymentDetails.currency)}
                  </div>
                </div>
              </div>

              <div className="font-small-2" style={{ textAlign: "justify" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <hr style={{ width: "40%", marginLeft: 0 }} />
                  <Lock size={14} />
                  <hr style={{ width: "40%", marginRight: 0 }} />
                </div>
                <p className="mb-1">
                All transaction information passed to, and used on, this payment form is encrypted during sending using
                the latest encryption and hashing techniques available. No personal information (including your card
                details) is ever passed over the internet or stored on our systems unencrypted.
                </p>
               { (paymentDetails.contact_c?.length>5 || paymentDetails.contact_p?.length>5)&& <i> For any queries please contact to {paymentDetails.contact_c?.length>3?paymentDetails.contact_c:paymentDetails.contact_p} </i>}
              </div>

            </div>
          </div>
        </Col>
        <Col lg="6" className="p-0" style={{ backgroundColor: "white" }}>
          <div className="d-flex align-items-center h-100">
            <div className="mx-auto mx-lg-0 responsive-padding" style={{ width: "540px", height: "413px" }}>
              <h4>Pay with Card</h4>
              <br />
              <Formik
                initialValues={{ nameOnCard: "", email: "", cvv: "", expiry: "", card_number: "" }}
                validationSchema={formSchema}
                onSubmit={handleFormSubmit}
                validateOnBlur={false}
              >
                {({ errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <Label for="email">Email</Label>
                          <Field
                            className={`form-control ${errors.email && touched.email && "is-invalid"}`}
                            name="email"
                            id="email"
                            autoComplete="off"
                            disabled={formSubmitting}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label>Card Information</Label>
                          <div id="card-element"></div>

                          <div className="card-information">
                            <CardNumberElement
                              className="form-control"
                              onChange={(e) => {
                                handleBrandChange(e.brand);
                                setFieldValue("card_number", e.complete);
                                if (cardDeclined) setCardDeclined(false);
                              }}
                              onBlur={() => !touched.card_number && setFieldTouched("card_number", true)}
                              options={{
                                placeholder: "0000 0000 0000 0000",
                                classes: {
                                  invalid: "is-invalid",
                                  focus: "focused-input",
                                  base: `${errors.card_number && touched.card_number && "is-invalid"} ${
                                    formSubmitting && "disabled-input"
                                  } ${cardDeclined && "is-invalid"}`,
                                },
                                style: { base: baseInputStyles },
                              }}
                            />
                            <CardExpiryElement
                              className="form-control"
                              onChange={(e) => setFieldValue("expiry", e.complete)}
                              onBlur={() => !touched.expiry && setFieldTouched("expiry", true)}
                              options={{
                                classes: {
                                  invalid: "is-invalid",
                                  focus: "focused-input",
                                  base: `${errors.expiry && touched.expiry && "is-invalid"} ${
                                    formSubmitting && "disabled-input"
                                  }`,
                                },
                                style: { base: baseInputStyles },
                              }}
                            />
                            <CardCvcElement
                              className="form-control"
                              onChange={(e) => setFieldValue("cvv", e.complete)}
                              onBlur={() => !touched.cvv && setFieldTouched("cvv", true)}
                              options={{
                                classes: {
                                  invalid: "is-invalid",
                                  focus: "focused-input",
                                  base: `${errors.cvv && touched.cvv && "is-invalid"} ${
                                    formSubmitting && "disabled-input"
                                  }`,
                                },
                                style: { base: baseInputStyles },
                              }}
                            />
                            <div
                              className="card-brand-logo"
                              style={{ background: formSubmitting ? "#efefef" : "#fff" }}
                            >
                              <img className="img-fluid" src={cardBrands[cardBrand]} style={{ width: "30px" }} />
                            </div>
                            {cardDeclined ? (
                              <div className="text-danger font-small-2" style={{ marginTop: "0.1rem" }}>
                                Your card was declined. Please try again with a different card.
                              </div>
                            ) : null}
                          </div>
                        </FormGroup>
                      </Col>
                      <Col sm="12">
                        <FormGroup>
                          <Label for="nameOnCard">Name on card</Label>
                          <Field
                            className={`form-control ${errors.nameOnCard && touched.nameOnCard && "is-invalid"}`}
                            id="nameOnCard"
                            name="nameOnCard"
                            autoComplete="off"
                            disabled={formSubmitting}
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" className="mt-1">
                        <div className="mb-1 text-center font-small-2">
                          <Lock size={12} style={{ marginRight: "0.5rem" }} />
                          Your transaction is secured with 128-bit SSL encryption
                        </div>
                        <FormGroup>
                          <Button block className="py-1" color="primary" onClick={handleSubmit}>
                            {!formSubmitting ? (
                              `Pay ${formatMoney(paymentDetails.amount, paymentDetails.currency)}`
                            ) : (
                              <div className="d-flex align-items-center justify-content-center">
                                <Spinner size="sm" className="mr-1" /> Processing ...
                              </div>
                            )}
                          </Button>
                         { paymentDetails?.cancel_url && <p className="text-center font-small-2 cursor-pointer  font-weight-bolder"> <i><p  onClick={()=> window.location.href = `${process.env.REACT_APP_API_URL}/stripe/cancel?unique_link_key=${paymentDetails.unique_link_key}`} >Cancel Payment</p></i></p>}
                        </FormGroup>
                        
                      </Col>
                      <Col>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardCheckout;
