// ** React Imports
import ReactDOM from "react-dom";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";

import "./style.scss";

// ** Lazy load app
import Router from "./Router";

ReactDOM.render(<Router />, document.getElementById("root"));
